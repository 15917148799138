import * as amplitude from '@amplitude/analytics-browser'

export const trackRegisterNowDropdownClick = () => {
	amplitude.track('customer-profile-click', {
		location: window.location.pathname,
	})
}

export const trackOnboardingComplete = (email: string) => {
	amplitude.track('onboarding-complete', {
		email: email,
	})
}

export const trackCheckoutCreateAccountSubmit = () => {
	amplitude.track('checkout-created-account', {
		location: 'checkout',
	})
}
